import { FC, ReactElement } from 'react';

import { FormSchema, FormValues } from '../components/molecules/Form/validate';
import { ReferenceInputProps } from '../components/pages/anpr/QuickSearch/SearchForm/SearchForm';

import * as nzpolice from './customers/nzpolice';

export interface PlatesOfInterestLocalisation {
  addPlateText: ReactElement;
  form?: {
    confirmText?: ReactElement;
    defaultValues?: (values: FormValues) => FormValues;
    elements: (props: any) => ReactElement;
    parseValues?: (values: any) => any;
  };
  otherDetails?: (extra: Record<string, any>) => ReactElement | null;
  schema?: FormSchema;
}

export interface QuickSearchLocalisation {
  infoCallout: ReactElement;
  onChangeFocusSubmit?: boolean;
  reference: {
    helperText: string;
    InputElement: FC<ReferenceInputProps>;
    labelText: string;
    required: boolean;
  };
  schema?: FormSchema;
}

export interface SearchAndExportLocalisation {
  preview: {
    infoCallout: ReactElement;
  };
  request: {
    approverCheckboxLabel: string;
    infoCallout: (searchCount: number) => ReactElement;
    caseEventId: {
      labelText: string;
      placeholder: string;
    };
    description: {
      placeholder: string;
      required: boolean;
    };
    schema?: FormSchema;
  };
}

export interface AnprApprovalLocalisation {
  infoText: string;
  referenceLabel: string;
}

export interface AnprAlertLocalisation {
  searchVehicleButton?: { reference: string };
}

interface AnprLocalisation {
  alert?: AnprAlertLocalisation;
  anprApproval?: AnprApprovalLocalisation;
  platesOfInterest?: PlatesOfInterestLocalisation;
  quickSearch?: QuickSearchLocalisation;
  searchAndExport?: SearchAndExportLocalisation;
}

export interface VaultUploadLocalisation {
  upload?: {
    caseEventId: {
      labelText: string;
    };
    ownerId: {
      labelText: string;
    };
    schema?: FormSchema;
  };
  uploadList?: {
    searchPlaceholder: string;
  };
}

export interface Localisation {
  anpr?: AnprLocalisation;
  _values: Record<string, string>;
  cssUrl?: string;
  get: (key: string) => string | undefined;
  getDecoded: <T = JSONValue>(key: string) => T | undefined;
  logoUrl?: string;
  twoStepSettingsShown?: boolean;
  vault?: VaultUploadLocalisation;
}

export const customers: Record<
  string,
  Omit<Localisation, 'get' | 'getDecoded' | '_values'> | undefined
> = {
  'default': {
    twoStepSettingsShown: true
  },

  'demo.dev.vgrid.io': {
    // Uncomment for testing
    // anpr: {
    //   alert: nzpolice.alert,
    //   anprApproval: nzpolice.anprApproval,
    //   platesOfInterest: nzpolice.platesOfInterest,
    //   quickSearch: nzpolice.quickSearch,
    //   searchAndExport: nzpolice.searchAndExport
    // },
    twoStepSettingsShown: true
    // vault: nzpolice.vault // Uncomment for testing
  },

  'nzpolice.securogroup.net': {
    anpr: {
      alert: nzpolice.alert,
      anprApproval: nzpolice.anprApproval,
      platesOfInterest: nzpolice.platesOfInterest,
      quickSearch: nzpolice.quickSearch,
      searchAndExport: nzpolice.searchAndExport
    },
    logoUrl: require('./customers/nzpolice/logo.png'),
    vault: nzpolice.vault
  }
};
