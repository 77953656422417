export type Day = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export enum Duration {
  DAY = 'DAY',
  HOUR = 'HOUR',
  MONTH = 'MONTH',
  WEEK = 'WEEK'
}
export interface DurationCounts {
  correct: number;
  incorrect: number;
  reads: number;
  unsure: number;
}

export interface Counts {
  [Duration.DAY]: Record<string, DurationCounts>;
  [Duration.HOUR]: Record<string, DurationCounts>;
  [Duration.MONTH]: Record<string, DurationCounts>;
  [Duration.WEEK]: Record<string, DurationCounts>;
}

export interface Table {
  fri: number[];
  mon: number[];
  sat: number[];
  sun: number[];
  thu: number[];
  tue: number[];
  wed: number[];
}

export interface Stream {
  id: string;
  lat: number;
  lon: number;
  name: string;
  siteId: string;
}

export interface Site {
  id: string;
  name: string;
}

export type Streams = Record<string, Stream>;

export interface Insights {
  counts: Counts;
  heatmap: Record<string, number>;
  sites: Site[];
  streams: Streams;
  tables: Record<string, Table>;
}

interface InsightsCounts extends DurationCounts {
  __typename: string;
  duration: Duration;
  id: string;
}

interface InsightsTable extends Table {
  __typename: string;
  id: string;
}

interface MapRow {
  __typename: string;
  count: number;
  id: string;
}

export interface InsightsResponse {
  day: InsightsCounts[];
  hour: InsightsCounts[];
  map: MapRow[];
  month: InsightsCounts[];
  sites: Site[];
  streams: Stream[];
  table: InsightsTable[];
  week: InsightsCounts[];
}
