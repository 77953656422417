import { createContext } from 'react';

import { Duration, Insights } from './types';

/**
 *
 */
export const InsightsContext = createContext<Insights>({
  counts: {
    [Duration.DAY]: {},
    [Duration.HOUR]: {},
    [Duration.MONTH]: {},
    [Duration.WEEK]: {}
  },
  heatmap: {},
  sites: [],
  streams: {},
  tables: {}
});
