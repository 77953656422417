import {
  Button,
  ControlGroup,
  Icon,
  Intent,
  MenuItem
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Select } from '@blueprintjs/select';
import {
  createElementObject,
  createElementHook,
  useLeafletContext
} from '@react-leaflet/core';
import { LeafletContextInterface } from '@react-leaflet/core/lib/context';
import { Map, DomUtil, DomEvent, Control, ControlOptions } from 'leaflet';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';

import styles from './LevelsControl.module.scss';

/**
 * This Leaflet control is a container for a React portal used to render the levels control.
 */
class LevelsControlContainer extends Control {
  private _container: HTMLElement;

  constructor(options: ControlOptions) {
    super(options);
    this._container = DomUtil.create(
      'div',
      'leaflet-bar leaflet-control leaflet-control-layers'
    );
    // Disable click propagation to prevent map click event when clicking on the control.
    DomEvent.disableClickPropagation(this._container);
  }

  onAdd(_map: Map) {
    return this._container;
  }

  onRemove(_map: Map) {
    // Nothing to do here
  }

  getContainer(): HTMLElement {
    return this._container;
  }
}

export interface LevelsControlProps extends ControlOptions {
  levels: number[];
  currentLevel: number | null;
  onChange: (level: number | null) => void;
}

function createLevelsControlContainer(
  props: LevelsControlProps,
  context: LeafletContextInterface
) {
  return createElementObject(new LevelsControlContainer(props), context);
}

function updateLevelsControlContainer(
  instance: LevelsControlContainer,
  props: LevelsControlProps,
  prevProps: LevelsControlProps
) {
  if (props.position !== prevProps.position && props.position) {
    instance.setPosition(props.position);
  }
}

const useLevelsControlContainer = createElementHook(
  createLevelsControlContainer,
  updateLevelsControlContainer
);

/**
 * Levels control component. This control allows the user to select a level from a list of levels. Up and down buttons
 * are also available to navigate through the levels.
 * @param props LevelsControlProps
 * @returns ReactElement
 */
export const LevelsControl: FC<LevelsControlProps> = (props) => {
  // The Leaflet context
  const context = useLeafletContext();

  // The container element ref, used to render the control as a React portal
  const levelsControlContainerRef = useLevelsControlContainer(props, context);

  const { currentLevel } = props;

  // Add the control to the map when the component mounts
  useEffect(() => {
    levelsControlContainerRef.current.instance.addTo(context.map);
    return () => {
      levelsControlContainerRef.current.instance.remove();
    };
  }, []);

  // Handle the up button click event
  const handleLevelUp = useCallback(() => {
    if (currentLevel === props.levels[0]) {
      props.onChange(null);
    } else if (currentLevel !== null) {
      const index = props.levels.indexOf(currentLevel);
      const nextLevel = props.levels[index - 1];
      if (nextLevel !== undefined) {
        props.onChange(nextLevel);
      }
    }
  }, [currentLevel, props.levels]);

  // Handle the down button click event
  const handleLevelDown = useCallback(() => {
    if (currentLevel === null) {
      props.onChange(props.levels[0]);
    } else {
      const index = props.levels.indexOf(currentLevel);
      const nextLevel = props.levels[index + 1];
      if (nextLevel !== undefined) {
        props.onChange(nextLevel);
      }
    }
  }, [currentLevel, props.levels]);

  const [query, setQuery] = useState<string>('');

  // Create the options for the select element
  const options = useMemo(() => {
    if (query === '') {
      return ['All', ...props.levels];
    }
    return [
      'All',
      ...props.levels.filter(
        (level) =>
          level.toString().includes(query) ||
          (level === 0 && query.toUpperCase() === 'G')
      )
    ];
  }, [query, props.levels]);

  // Callback to get the level text for a given level
  const getLevelText = useCallback((level: string | number | null): string => {
    if (level === null) {
      return 'All';
    } else if (level === 0) {
      return 'G (0)';
    } else {
      return `${level}`;
    }
  }, []);

  // Render the control as a React portal inside the container element
  return createPortal(
    <ControlGroup vertical>
      <Button
        title="Level up"
        icon={IconNames.ARROW_UP}
        minimal
        intent={Intent.PRIMARY}
        onClick={handleLevelUp}
        disabled={currentLevel === null}
      />
      <Select
        popoverProps={{
          inheritDarkTheme: false,
          matchTargetWidth: true,
          minimal: true
        }}
        filterable={true}
        items={options}
        activeItem={currentLevel === null ? 'All' : currentLevel}
        query={query}
        onQueryChange={setQuery}
        menuProps={{
          className: styles.selectLevelMenu
        }}
        itemRenderer={(item, { handleClick, modifiers }) => {
          return (
            <MenuItem
              active={modifiers.active}
              disabled={modifiers.disabled}
              key={item}
              icon={
                item !== 0 ? (
                  <Icon icon={IconNames.OFFICE} size={12} />
                ) : (
                  <Icon icon={IconNames.Star} size={12} />
                )
              }
              onClick={handleClick}
              text={getLevelText(item)}
              textClassName={styles.menuItemText}
            />
          );
        }}
        onItemSelect={(item) => {
          props.onChange(item === 'All' ? null : Number(item));
        }}
      >
        <Button
          title="Select level"
          minimal
          className={styles.selectLevelButton}
          icon={IconNames.OFFICE}
          text={getLevelText(currentLevel)}
        />
      </Select>
      <Button
        title="Level down"
        icon={IconNames.ARROW_DOWN}
        minimal
        intent={Intent.PRIMARY}
        onClick={handleLevelDown}
        disabled={
          !props.levels.length ||
          currentLevel === props.levels[props.levels.length - 1]
        }
      />
    </ControlGroup>,
    levelsControlContainerRef.current.instance.getContainer()
  );
};
