import { gql } from '@apollo/client';

export const INSIGHTS = gql`
  fragment Counts on AnprInsightsCounts {
    correct
    duration
    id
    incorrect
    reads
    unsure
  }

  query anprInsights {
    day: anprInsightsCounts(duration: DAY) {
      ...Counts
    }

    hour: anprInsightsCounts(duration: HOUR) {
      ...Counts
    }

    map: anprInsightsMap {
      count
      id
    }

    month: anprInsightsCounts(duration: MONTH) {
      ...Counts
    }

    sites: anprInsightsSites {
      id
      name
    }

    streams: anprInsightsStreams {
      id
      lat
      lon
      name
      siteId
    }

    table: anprInsightsTable {
      fri
      id
      mon
      sat
      sun
      thu
      tue
      wed
    }

    week: anprInsightsCounts(duration: WEEK) {
      ...Counts
    }
  }
`;
